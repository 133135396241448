import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import blankProfile from '../Assets/new_trellis_assets/Panel/Panel_Blank_SquareTop_StdPicket.svg'
import blankProfilePreview from '../Assets/PanelAssets/BlankProfilePreview.svg';
import blankProfilePreviewGrey from '../Assets/PanelAssets/BlankProfilePreviewGrey.svg';
import colonialProfile from '../Assets/new_trellis_assets/Panel/Panel_Colonial_SquareTop_StdPicket.svg'
import colonialProfilePreview from '../Assets/PanelAssets/ColonialProfilePreview.svg';
import colonialProfilePreviewGrey from '../Assets/PanelAssets/ColonialProfilePreviewGrey.svg';
import gothicProfile from '../Assets/new_trellis_assets/Panel/Panel_Gothic_SquareTop_StdPicket.svg'
import gothicProfilePreview from '../Assets/PanelAssets/GothicProfilePreview.svg';
import gothicProfilePreviewGrey from '../Assets/PanelAssets/GothicProfilePreviewGrey.svg';
import { ProductTypeName } from '../models/types';
import { buttonSX } from '../styles';

export interface SelectProfileProps {
  productSelected: ProductTypeName;
}

export const SelectProfile: React.FC<SelectProfileProps> = ({
  productSelected,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isConsumerPage = window.location.pathname.includes('consumer');


  const [profileType, setProfileType] = useState<
    'blank' | 'colonial' | 'gothic'
  >('blank');

  const transitions = useTransition(profileType, profileType, {
    initial: null,
    from: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
      position: 'absolute',
    },
    enter: {
      opacity: 1,
      position: 'absolute',
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      position: 'absolute',
      transform: 'translate3d(50%,0,0)',
    },
    config: {
      easing: (x) =>
        x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
      duration: 450,
    },
  });

  const renderIcon = (item: any) => {
    switch (item) {
      case 'blank':
        return (
          <img
            src={blankProfile}
            alt="Blank Profile"
            className="IconPreview ProfileIcon"
          />
        );
      case 'colonial':
        return (
          <img
            src={colonialProfile}
            alt="Colonial Profile"
            className="IconPreview ProfileIcon"
          />
        );
      case 'gothic':
        return (
          <img
            src={gothicProfile}
            alt="Gothic Profile"
            className="IconPreview ProfileIcon"
          />
        );
    }
  };
  const displayProfileIcon = () => {
    return transitions.map(({ item, key, props }) => (
      <animated.div key={key} style={props} className="Animation">
        {renderIcon(item)}
      </animated.div>
    ));
  };

  const displayProfilePreview = () => {
    return (
      <div className="SideProfileSelect">
        <Button
          className="BlankProfilePreview"
          onClick={() => setProfileType('blank')}
        >
          <img
            src={
              profileType === 'blank'
                ? blankProfilePreview
                : blankProfilePreviewGrey
            }
            alt="Blank Profile"
          />
        </Button>
        <Button
          className="GothicProfilePreview"
          onClick={() => setProfileType('gothic')}
        >
          <img
            src={
              profileType === 'gothic'
                ? gothicProfilePreview
                : gothicProfilePreviewGrey
            }
            alt="Gothic Profile"
          />
        </Button>
        <Button
          className="ColonialProfilePreview"
          onClick={() => setProfileType('colonial')}
        >
          <img
            src={
              profileType === 'colonial'
                ? colonialProfilePreview
                : colonialProfilePreviewGrey
            }
            alt="Colonial Profile"
          />
        </Button>
      </div>
    );
  };

  const handleNext = () => {
    dispatch({
      type: 'SET_FIELD',
      productType: productSelected,
      fieldName: 'picketProfile',
      value: profileType,
    });

    if (profileType !== 'blank') {
      dispatch({
        type: 'SET_FIELD',
        productType: productSelected,
        fieldName: 'picketSize',
        value: 'standard',
      });
    } else {
      dispatch({
        type: 'SET_FIELD',
        productType: productSelected,
        fieldName: 'picketSize',
        value: 'large',
      });
    }

    if (productSelected === 'panel' && !isConsumerPage) {
      if (profileType !== 'blank') {
        history.push('/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
        dispatch({ type: 'PROGRESS_STEP' });
        return;
      } else {
        history.push('/rails');
        dispatch({ type: 'PROGRESS_STEP' });
      }
    }

    if (productSelected === 'panel' && isConsumerPage) {
      if (profileType !== 'blank') {
        history.push('/consumer/panel/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
        dispatch({ type: 'PROGRESS_STEP' });
        return;
      } else {
        history.push('/consumer/panel/rails');
        dispatch({ type: 'PROGRESS_STEP' });
      }
    }

    if (productSelected === 'gate') {
      if (window.location.pathname.includes('consumer')) {
        history.push('/consumer/gate/rails');
        dispatch({ type: 'PROGRESS_STEP' });
      } else {
        history.push('/rails');
        dispatch({ type: 'PROGRESS_STEP' });
      }
    }
  };

  return (
    <ProfileStyle profileType={profileType}>
      <div className="MainContent">
        <Typography variant="h6" className="HeaderText">
          Select the picket profile of your
          {productSelected === 'panel' ? ' panel' : ' gate'}
        </Typography>
        <div className="InputContainer">
          <div className="ProfileInput">
            <Button
              className="BlankButton"
              onClick={() => setProfileType('blank')}
            >
              Blank
            </Button>
            <Button
              className="GothicButton"
              onClick={() => setProfileType('gothic')}
            >
              Gothic
            </Button>
            <Button
              className="ColonialButton"
              onClick={() => setProfileType('colonial')}
            >
              Colonial
            </Button>
          </div>
          <div className="ProfileWrapper">
            {displayProfilePreview()}
            <div className="ProfileAnimationContainer">
              {displayProfileIcon()}
            </div>
          </div>
        </div>
      </div>

      <Button
        onClick={() => handleNext()}
        variant="contained"
        sx={buttonSX}
      >
        Next
      </Button>
    </ProfileStyle>
  );
};

const ProfileStyle = styled.div<{ profileType: string }>`
  height: 98%;
  display: flex;
  flex-direction: column;

  .InputContainer {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  .ProfileWrapper {
    display: flex;
    .SideProfileSelect {
      display: flex;
      flex-direction: column;
      z-index: 1000;
    }
  }

  .ProfileAnimationContainer {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    padding: 5px 0px;
    width: 100%;
  }

  .ProfileInput {
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
    font-size: 20px;

    .BlankButton {
      font-weight: ${(props) => props.profileType === 'blank' && 600};
      color: ${(props) =>
    props.profileType === 'blank' ? props.theme.green : props.theme.grey};
    }

    .GothicButton {
      font-weight: ${(props) => props.profileType === 'gothic' && 600};
      color: ${(props) =>
    props.profileType === 'gothic' ? props.theme.green : props.theme.grey};
    }

    .ColonialButton {
      font-weight: ${(props) => props.profileType === 'colonial' && 600};
      color: ${(props) =>
    props.profileType === 'colonial'
      ? props.theme.green
      : props.theme.grey};
    }
  }
`;
