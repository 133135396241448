import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { QuotePayload } from '../models/interfaces';
import { MainComponentStyle } from '../Routes';
import { useTradeStores } from '../utils';
import { EmailFinish } from './EmailFinish';
import { FinishQuote } from './FinishQuote';
import { Header } from './Header';
import { NewQuoteAdmin } from './NewQuoteAdmin';
import { SelectDimensions } from './SelectDimensions';
import { SelectProfile } from './SelectProfile';
import { SelectQuantity } from './SelectQuantity';
import { SelectRailSize } from './SelectRailSize';
import { SelectStyle } from './SelectStyle';

export const DEBTOR_ROUTE = '';

export const DebtorWorkflow: React.FC = () => {
  const [openSummary, setOpenSummary] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const trellisSteps = ['Type', 'Dimensions', 'Styles', 'Summary'];
  const panelAndGatesSteps = [
    'Type',
    'Dimensions',
    'Styles',
    'Profile',
    'Size',
    'Summary',
  ];

  const productSelected = useSelector((quotePayload: QuotePayload) => quotePayload.product);
  const progressStep = useSelector<QuotePayload>(
    (quotePayload) => quotePayload.stepCounter,
  ) as number;

  const { data } = useTradeStores();

  const backToStart = () => {
    history.push('/');

    dispatch({
      type: 'PROGRESS_STEP',
      reset: true,
    });
  };

  const handleFinish = () => {
    history.push('/finish');

    dispatch({
      type: 'PROGRESS_STEP',
      stepCounter: 7,
    });
  };

  const transitions = useTransition(progressStep, (p) => p, {
    from: { opacity: 0, position: 'absolute', height: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      easing: (t: number) => t * (2 - t),
      duration: 350,
    },
    reset: true,
  });

  useEffect(() => {
    return history.listen((_, action) => {
      if (action !== 'POP') return;

      if (
        (productSelected === 'trellis' && progressStep === 5) ||
        (productSelected !== 'trellis' && progressStep === 6)
      ) {
        dispatch({
          type: 'SET_FIELD',
          productType: productSelected,
          fieldName: 'added',
          value: false,
        });
      }

      dispatch({
        type: 'PROGRESS_STEP',
        decrement: true,
      });
    });
  }, [history, productSelected, progressStep, dispatch]);

  const renderComponent = () => {
    const renderStep = (item: number) => {
      switch (item) {
        case 0:
          return <NewQuoteAdmin />;
        case 1:
          return (
            <SelectDimensions
              productSelected={productSelected}
              pushUrl={DEBTOR_ROUTE}
            />
          );
        case 2:
          return (
            <SelectStyle
              productSelected={productSelected}
              pushUrl={DEBTOR_ROUTE}
            />
          );
        case 3:
          if (productSelected === 'trellis') {
            return (
              <SelectQuantity
                nextButtonText="Add to Quote"
                productSelected={productSelected}
                pushUrl={DEBTOR_ROUTE}
              />
            );
          } else {
            return <SelectProfile productSelected={productSelected} />;
          }
        case 4:
          if (productSelected === 'trellis') {
            return (
              <FinishQuote
                backToStart={backToStart}
                productSelected={productSelected}
                setOpenSummary={setOpenSummary}
                completeButtonText="Get Quote"
              />
            );
          } else {
            return <SelectRailSize productSelected={productSelected} />;
          }
        case 5:
          return (
            <SelectQuantity
              nextButtonText="Add to Quote"
              productSelected={productSelected}
              pushUrl={DEBTOR_ROUTE}
            />
          );
        case 6:
          return (
            <FinishQuote
              backToStart={backToStart}
              productSelected={productSelected}
              setOpenSummary={setOpenSummary}
              completeButtonText="Get Quote"
            />
          );
        case 7:
          return <EmailFinish backToStart={backToStart} tradeStores={data} />;
      }
    };

    return transitions.map(({ item, key, props }) => (
      <div className="Container" key={key}>
        <animated.div key={key} style={props} className="Animation">
          {renderStep(item)}
        </animated.div>
      </div>
    ));
  };

  return (
    <>
      <Header
        finishButtonText="Get Quote"
        backUrl="/"
        handleFinish={handleFinish}
        progressStep={progressStep}
        setOpenSummary={setOpenSummary}
        openSummary={openSummary}
        productSelected={productSelected}
        _handleBack={history.goBack}
        progressSteps={
          productSelected === 'trellis' ? trellisSteps : panelAndGatesSteps
        }
      />
      <MainComponentStyle>{renderComponent()}</MainComponentStyle>
    </>
  );
};
