import { QuotePayload } from '../models/interfaces';
import { ProductTypeName } from '../models/types';

// ? IMPORT ACTIONS ===============================================
// ? ==============================================================
import handleTrellisActions from './trellisActions';
import handleGateActions from './gateActions';
import handlePanelActions from './panelActions';

// ? INITIAL STATE ================================================
// ? ==============================================================
const initialState: QuotePayload = {
  email: '',
  product: 'trellis' as ProductTypeName,
  trellisData: [
    {
      shape: { height1: 0, height2: 0, width: 0, angle: null },
      framed: false,
      name: 'trellis',
      quantity: 0,
      fixing: 'stainless',
      style: 'diagonal',
      added: false,
    },
  ],
  gateData: [
    {
      shape: { gateHeight: 0, gateWidth: 0, hingePlacement: 'left' },
      quantity: 0,
      name: 'gate',
      picketProfile: 'blank',
      picketSize: 'large',
      railSize: 'standard',
      style: 'square',
      added: false,
    },
  ],
  panelData: [
    {
      shape: { height: 0, width: 0 },
      name: 'panel',
      quantity: 0,
      picketSize: 'standard',
      railSize: 'large',
      picketProfile: 'blank',
      style: 'square',
      added: false,
    },
  ],
  totalItems: 0,
  stepCounter: 0,
};

// ? INTERFACES ===================================================
// ? ==============================================================

interface NewEmptyProductAction {
  type: 'NEW_EMPTY_PRODUCT';
  productType: ProductTypeName;
}

interface ClearQuotesAction {
  type: 'CLEAR_QUOTES';
}

interface TotalItemsAction {
  type: 'SET_TOTAL_ITEMS';
  decrement: boolean;
}

interface SetEmailAction {
  type: 'SET_EMAIL';
  email: string;
}

interface SetProductTypeAction {
  type: 'SET_PRODUCT_TYPE';
  product: ProductTypeName;
}

interface DeleteAction {
  product: ProductTypeName;
  type: 'DELETE_ITEM';
  index: number;
}

interface ChangeQuantityAction {
  product: ProductTypeName;
  type: 'CHANGE_QUANTITY';
  quantity: number;
  index: number;
}

interface ProgressStep {
  type: 'PROGRESS_STEP';
  product: ProductTypeName;
  reset: boolean;
  decrement: boolean;
  stepCounter: number;
}

interface SetFieldAction {
  type: 'SET_FIELD';
  productType: ProductTypeName;
  fieldName: string;
  value: any;
}

interface SetFieldsAction {
  type: 'SET_FIELDS';
  productType: ProductTypeName;
  fieldValue: [{ fieldName: string; value: string }];
}

export type TrelliAction =
  | ProgressStep
  | NewEmptyProductAction
  | TotalItemsAction
  | SetProductTypeAction
  | SetEmailAction
  | DeleteAction
  | ClearQuotesAction
  | ChangeQuantityAction
  | SetFieldAction
  | SetFieldsAction;

// ? ROOT REDUCER =================================================
// ? ==============================================================
export const rootReducer = (state = initialState, action: TrelliAction): QuotePayload => {
  switch (action.type) {
    case 'PROGRESS_STEP':
      return progressStepHandler(state, action);
    case 'SET_PRODUCT_TYPE':
      return {
        ...state,
        product: action.product,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.email,
      };
    case 'CLEAR_QUOTES':
      return initialState;
    case 'NEW_EMPTY_PRODUCT':
    case 'SET_FIELD':
    case 'SET_FIELDS':
    case 'DELETE_ITEM':
    case 'CHANGE_QUANTITY':
      return handleProductBasedActions(state, action);
    default:
      return state;
  }
}

// ? ACTION CREATORS ===============================================
// ? ===============================================================
function progressStepHandler(state: QuotePayload, action: ProgressStep) {
  if (action.reset) {
    return {
      ...state,
      stepCounter: 0,
    };
  }
  if (action.stepCounter) {
    return {
      ...state,
      stepCounter: action.stepCounter,
    };
  }
  return {
    ...state,
    stepCounter: action.decrement ? state.stepCounter - 1 : state.stepCounter + 1,
  };
}

function handleProductBasedActions(state: QuotePayload, action: TrelliAction) {
  if ('productType' in action) {
    switch (action.productType) {
      case 'trellis':
        return handleTrellisActions(state, action);
      case 'gate':
        return handleGateActions(state, action);
      case 'panel':
        return handlePanelActions(state, action);
      default:
        return state;
    }
  }
  return state;
}

