import { QuotePayload, PanelData } from "../models/interfaces";
import { ProductTypeName } from "../models/types";
import { TrelliAction } from "./redux";

function handlePanelActions(state: QuotePayload, action: TrelliAction) {
  switch (action.type) {
    case 'NEW_EMPTY_PRODUCT':
      return {
        ...state,
        product: 'panel' as ProductTypeName,
        panelData: state.panelData.concat([
          {
            shape: { height: 0, width: 0 },
            quantity: 0,
            name: 'panel',
            picketSize: 'standard',
            railSize: 'large',
            picketProfile: 'blank',
            style: 'square',
            added: false,
          } as PanelData,
        ]),
      }
    case 'SET_FIELD': {
      const parts = action.fieldName.split('.');
      const current = state.panelData[state.panelData.length - 1];

      let newCurrent;
      if (parts[0] === 'shape') {
        newCurrent = {
          ...current,
          shape: {
            ...current.shape,
            [parts[1]]: action.value,
          },
        };
      }
      else {
        newCurrent = { ...current, [parts[0]]: action.value };
      }
      return {
        ...state,
        panelData: state.panelData
          .slice(0, state.panelData.length - 1)
          //@ts-ignore
          .concat([newCurrent]),
      };
    }
    case 'SET_FIELDS': {
      let newState = state;

      action.fieldValue.map((fieldValue) => {
        const parts = fieldValue.fieldName.split('.');
        const current = newState.panelData[newState.panelData.length - 1];

        let newCurrent;

        if (parts[0] === 'shape') {
          newCurrent = {
            ...current,
            shape: {
              ...current.shape,
              [parts[1]]: fieldValue.value,
            },
          };
        } else {
          newCurrent = { ...current, [parts[0]]: fieldValue.value };
        }

        newState = {
          ...newState,
          panelData: newState.panelData
            .slice(0, newState.panelData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
        return newState;
      });
      return newState;
    }
    case 'DELETE_ITEM':
      return {
        ...state,
        panelData: state.panelData.filter(
          (_: any, index: number) =>
            state.panelData[index] !== state.panelData[action.index],
        )
      }
    case 'CHANGE_QUANTITY': {
      state.panelData[action.index].quantity = action.quantity;
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default handlePanelActions;