import React, { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Input, InputBaseComponentProps } from '@mui/material';
import square00 from '../../Assets/MiscAssets/SquareTop.svg';
import square from '../../Assets/new_trellis_assets/Panel/Panel_Blank_SquareTop_StdPicket.svg'
import { blurInput, focusInput } from '../../utils';
import { ErrorFieldType } from '../../validate-dimensions';

export interface PanelDimensionsProps {
  inputProps: InputBaseComponentProps;
  squareWidth: number;
  setSquareWidth: (e: number) => void;
  squareHeight: number;
  setSquareHeight: (e: number) => void;
  errorField?: ErrorFieldType;
}

export const PanelDimensions: React.FC<PanelDimensionsProps> = ({
  inputProps,
  squareWidth,
  setSquareWidth,
  squareHeight,
  setSquareHeight,
  errorField,
}) => {
  const displayProductIcon = () => {
    return <img className="Icon" src={square} alt="trellis dimension" />;
  };
  const heightRef = useRef<HTMLInputElement>(null);
  const widthRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div className="MainContent">
        <Typography variant="h6" className="HeaderText">
          Set the dimensions and style of your panel
        </Typography>
        <div className="DimensionsInput">
          {displayProductIcon()}
          <FormControl className="LeftInput" variant="outlined">
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              required
              error={errorField === ErrorFieldType.height1}
              inputRef={heightRef}
              inputProps={inputProps}
              id="heightInMM"
              type="number"
              placeholder="Height"
              onKeyDown={(e) => e.key === 'Enter' && focusInput(widthRef)}
              autoFocus
              value={squareHeight !== 0 && squareHeight}
              onChange={(e) => setSquareHeight(parseInt(e.target.value, 10))}
            />
            <div className="MM">mm</div>
          </FormControl>
          <FormControl className="BottomInput" variant="outlined">
            <Input
              disableUnderline={true}
              autoComplete="off"
              required
              error={errorField === ErrorFieldType.width}
              inputRef={widthRef}
              className="InputBox"
              id="widthInMM"
              placeholder="Width"
              onKeyDown={(e) => e.key === 'Enter' && blurInput(widthRef)}
              type="number"
              value={squareWidth !== 0 && squareWidth}
              onChange={(e) => setSquareWidth(parseInt(e.target.value, 10))}
            />
            <div className="MM MM-width">mm</div>
          </FormControl>
          <FormControl className="RightInput" variant="outlined">
            <div style={{ width: 150 }} />
          </FormControl>
        </div>
      </div>
    </>
  );
};
