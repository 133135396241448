import React from 'react';
import styled from 'styled-components';
import { TopStyle, TrellisStyleType, TrellisAngle, PanelOrGate, HingePlacement, RailSize, PicketProfile, PicketSize, ItemData, IconType } from './models/types';

// ? TRELLIS ===============================================================
import venetian from '../src/Assets/new_trellis_assets/Trellis/Venetian_2.svg';
import venetianAngle from '../src/Assets/new_trellis_assets/Trellis/Venetian_2_Angled.svg';
import square from '../src/Assets/new_trellis_assets/Trellis/Square_2.svg';
import squareAngle from '../src/Assets/new_trellis_assets/Trellis/Square_2_Angled.svg';
import diagonal from '../src/Assets/new_trellis_assets/Trellis/Diagonal_2.svg';
import diagonalAngle from '../src/Assets/new_trellis_assets/Trellis/Diagonal_2_Angled.svg';
import oriental from '../src/Assets/new_trellis_assets/Trellis/Oriental_2.svg';
import orientalAngle from '../src/Assets/new_trellis_assets/Trellis/Oriental_2_Angled.svg';

// ? PANELS ===============================================================
import panelBlankSquareTop from '../src/Assets/new_trellis_assets/Panel/Panel_Blank_SquareTop_StdPicket.svg';
import panelBlankRoundTop from '../src/Assets/new_trellis_assets/Panel/Panel_Blank_CurvedTop_StdPicket.svg';
import panelLargeBlankSquareTop from '../src/Assets/new_trellis_assets/Panel/Panel_Blank_SquareTop_LargePicket.svg';
import panelLargeBlankRoundTop from '../src/Assets/new_trellis_assets/Panel/Panel_Blank_CurvedTop_LargePicket.svg';

import panelColonialSquareTop from '../src/Assets/new_trellis_assets/Panel/Panel_Colonial_SquareTop_StdPicket.svg';
import panelColonialRoundTop from '../src/Assets/new_trellis_assets/Panel/Panel_Colonial_CurvedTop_StdPicket.svg';

import panelGothicSquareTop from '../src/Assets/new_trellis_assets/Panel/Panel_Gothic_SquareTop_StdPicket.svg';
import panelGothicRoundTop from '../src/Assets/new_trellis_assets/Panel/Panel_Gothic_CurvedTop_StdPicket.svg';

// ? GATES ===============================================================
import gateHingeLeft from '../src/Assets/new_trellis_assets/Gate/Gate_LH.svg'
import gateHingeRight from '../src/Assets/new_trellis_assets/Gate/Gate_RH.svg'

// left side hinge gates standard rails
import gateLeftBlankSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Blank_SquareTop_StdRail.svg'
import gateLeftBlankSquareTopWide from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Blank_SquareTop_StdRail_WidePaling.svg'
import gateLeftBlankRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Blank_RoundTop_StdRail.svg'
import gateLeftBlankRoundTopWide from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Blank_RoundTop_StdRail_WidePaling.svg'

import gateLeftColonialSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Colonial_SquareTop_StdRail.svg'
import gateLeftColonialRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Colonial_RoundTop_StdRail.svg'

import gateLeftGothicSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Gothic_SquareTop_StdRail.svg'
import gateLeftGothicRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_LH_Gothic_RoundTop_StdRail.svg'

// right side hinge gates standard rails
import gateRightBlankSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Blank_SquareTop_StdRail.svg'
import gateRightBlankSquareTopWide from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Blank_SquareTop_StdRail_WidePaling.svg'
import gateRightBlankRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Blank_RoundTop_StdRail.svg'
import gateRightBlankRoundTopWide from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Blank_RoundTop_StdRail_WidePaling.svg'

import gateRightColonialSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Colonial_SquareTop_StdRail.svg'
import gateRightColonialRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Colonial_RoundTop_StdRail.svg'

import gateRightGothicSquareTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Gothic_SquareTop_StdRail.svg'
import gateRightGothicRoundTop from '../src/Assets/new_trellis_assets/Gate/stdRail/Gate_RH_Gothic_RoundTop_StdRail.svg'

// left hand hinge gates large rails
import gateLeftLargeBlankSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Blank_SquareTop_LrgRail.svg'
import gateLeftLargeBlankSquareTopWide from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Blank_SquareTop_LrgRail_WidePaling.svg'
import gateLeftLargeBlankRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Blank_RoundTop_LrgRail.svg'
import gateLeftLargeBlankRoundTopWide from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Blank_RoundTop_LrgRail_WidePaling.svg'

import gateLeftLargeColonialSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Colonial_SquareTop_LrgRail.svg'
import gateLeftLargeColonialRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Colonial_RoundTop_LrgRail.svg'

import gateLeftLargeGothicSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Gothic_SquareTop_LrgRail.svg'
import gateLeftLargeGothicRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_LH_Gothic_RoundTop_LrgRail.svg'

// right hand hinge gates large rails
import gateRightLargeBlankSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Blank_SquareTop_LrgRail.svg'
import gateRightLargeBlankSquareTopWide from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Blank_SquareTop_LrgRail_WidePaling.svg'
import gateRightLargeBlankRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Blank_RoundTop_LrgRail.svg'
import gateRightLargeBlankRoundTopWide from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Blank_RoundTop_LrgRail_WidePaling.svg'

import gateRightLargeColonialSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Colonial_SquareTop_LrgRail.svg'
import gateRightLargeColonialRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Colonial_RoundTop_LrgRail.svg'

import gateRightLargeGothicSquareTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Gothic_SquareTop_LrgRail.svg'
import gateRightLargeGothicRoundTop from '../src/Assets/new_trellis_assets/Gate/lrgRail/Gate_RH_Gothic_RoundTop_LrgRail.svg'

import largePicketsIcon from '../src/Assets/PanelAssets/LargePicketsSmall.svg';
import standardPicketsIcon from '../src/Assets/PanelAssets/StandardPicketsSmall.svg';

import largeRailsIcon from '../src/Assets/PanelAssets/LargeRailsSmall.svg';
import standardRailsIcon from '../src/Assets/PanelAssets/StandardRailsSmall.svg';
import { ProductTypeName } from './models/types';

// ? Panel images Keys ==========================================================
export const panelImages = {
  standard: {
    blank: {
      squareTop: panelBlankSquareTop,
      roundTop: panelBlankRoundTop,
    },
    colonial: {
      squareTop: panelColonialSquareTop,
      roundTop: panelColonialRoundTop,
    },
    gothic: {
      squareTop: panelGothicSquareTop,
      roundTop: panelGothicRoundTop,
    },
  },
  large: {
    blank: {
      squareTop: panelLargeBlankSquareTop,
      roundTop: panelLargeBlankRoundTop,
    },
  }
}

// ? Gate Images Keys ============================================================
export const gateImages = {
  left: {
    hinge: gateHingeLeft,
    standard: {
      blank: {
        squareTop: gateLeftBlankSquareTop,
        squareTopWide: gateLeftBlankSquareTopWide,
        roundTop: gateLeftBlankRoundTop,
        roundTopWide: gateLeftBlankRoundTopWide,
      },
      colonial: {
        squareTop: gateLeftColonialSquareTop,
        roundTop: gateLeftColonialRoundTop,
      },
      gothic: {
        squareTop: gateLeftGothicSquareTop,
        roundTop: gateLeftGothicRoundTop,
      },

    },
    large: {
      blank: {
        squareTop: gateLeftLargeBlankSquareTop,
        squareTopWide: gateLeftLargeBlankSquareTopWide,
        roundTop: gateLeftLargeBlankRoundTop,
        roundTopWide: gateLeftLargeBlankRoundTopWide,
      },
      colonial: {
        squareTop: gateLeftLargeColonialSquareTop,
        roundTop: gateLeftLargeColonialRoundTop,
      },
      gothic: {
        squareTop: gateLeftLargeGothicSquareTop,
        roundTop: gateLeftLargeGothicRoundTop,
      },
    },
  },
  right: {
    hinge: gateHingeRight,
    standard: {
      blank: {
        squareTop: gateRightBlankSquareTop,
        squareTopWide: gateRightBlankSquareTopWide,
        roundTop: gateRightBlankRoundTop,
        roundTopWide: gateRightBlankRoundTopWide,
      },
      colonial: {
        squareTop: gateRightColonialSquareTop,
        roundTop: gateRightColonialRoundTop,
      },
      gothic: {
        squareTop: gateRightGothicSquareTop,
        roundTop: gateRightGothicRoundTop,
      },
    },
    large: {
      blank: {
        squareTop: gateRightLargeBlankSquareTop,
        squareTopWide: gateRightLargeBlankSquareTopWide,
        roundTop: gateRightLargeBlankRoundTop,
        roundTopWide: gateRightLargeBlankRoundTopWide,
      },
      colonial: {
        squareTop: gateRightLargeColonialSquareTop,
        roundTop: gateRightLargeColonialRoundTop,
      },
      gothic: {
        squareTop: gateRightLargeGothicSquareTop,
        roundTop: gateRightLargeGothicRoundTop,
      },
    },
  },
}
// ? Types ======================================================================

export const squareTop = (kind: PanelOrGate, thumbnail: boolean = false, hinge?: HingePlacement) => {
  const gateImg = hinge === 'left' ? gateLeftBlankSquareTop : gateRightBlankSquareTop;
  const src = kind === 'panel' ? panelBlankSquareTop : gateImg;
  const className = thumbnail ? 'SquareTopIcon' : 'IconPreview';
  return <img src={src} alt="Square Top" className={className} />;
};

export const roundTop = (kind: PanelOrGate, thumbnail?: boolean, hinge?: HingePlacement) => {
  const gateImg = hinge === 'left' ? gateLeftBlankRoundTop : gateRightBlankRoundTop;
  const src = kind === 'panel' ? panelBlankRoundTop : gateImg;
  const className = thumbnail ? 'RoundTopIcon' : 'IconPreview';
  return <img src={src} alt="Round Top" className={className} />;
};

// export const galvanized = (thumbnail?: boolean) => {
//   return thumbnail ? (
//     <img src={galvanizedIcon} alt="Round Top" className="GalvanizedIcon" />
//   ) : (
//     <img src={galvanizedIcon} alt="Round Top" className="IconPreview" />
//   );
// };

// export const stainless = (thumbnail?: boolean) => {
//   return thumbnail ? (
//     <img src={stainlessIcon} alt="Round Top" className="StainlessIcon" />
//   ) : (
//     <img src={stainlessIcon} alt="Round Top" className="IconPreview" />
//   );
// };

export const trellisStyleIcon = (
  style: TrellisStyleType,
  angle: TrellisAngle,
  thumbnail?: boolean,
) => {
  switch (style) {
    case 'diagonal':
      return angle === 'left' ? (
        <img
          src={diagonalAngle}
          alt="Diagonal"
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={diagonalAngle}
          alt="Diagonal"
          style={{ transform: 'scaleX(-1)' }}
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={diagonal}
          alt="Diagonal"
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      );
    case 'square':
      return angle === 'left' ? (
        <img
          src={squareAngle}
          alt="Square"
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={squareAngle}
          alt="Square"
          style={{ transform: 'scaleX(-1)' }}
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={square}
          alt="Square"
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      );

    case 'oriental':
      return angle === 'left' ? (
        <img
          src={orientalAngle}
          alt="Oriental"
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={orientalAngle}
          alt="Oriental"
          style={{ transform: 'scaleX(-1)' }}
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={oriental}
          alt="Oriental"
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      );

    case 'venetian':
      return angle === 'left' ? (
        <img
          src={venetianAngle}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={venetianAngle}
          alt="Venetian"
          style={{ transform: 'scaleX(-1)' }}
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={venetian}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      );

    default:
      return (
        <img
          src={venetian}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      );
  }
};

export const gateIcon = (
  hinge: HingePlacement,
  railSize: RailSize,
  picketProfile: PicketProfile,
  picketSize: PicketSize,
  style: TopStyle,
  thumbnail: boolean = false,
) => {

  const displayImage = (gateImages[hinge] as any)[railSize][picketProfile][picketSize === 'standard' ? `${style}Top` : `${style}TopWide`];

  return (
    <GateIconStyle >
      <img
        className={thumbnail ? 'BaseIcon' : 'IconPreview'}
        src={displayImage}
        alt="gate"
      />
    </GateIconStyle>
  );
};

export const panelIcon = (
  picketSize: PicketSize,
  picketProfile: PicketProfile,
  style: TopStyle,
  thumbnail: boolean = false,
) => {
  const displayImage = (panelImages[picketSize] as any)[picketProfile][`${style}Top`];

  return (
    <GateIconStyle >
      <img
        className={thumbnail ? 'BaseIcon' : 'IconPreview'}
        src={displayImage}
        alt="panel"
      />
    </GateIconStyle>
  )
}

export const displaySummaryIcon = (
  productSelected: ItemData,
) => {
  if (productSelected.name === 'trellis') {
    return (
      <SummaryIconStyle productSelected={productSelected.name}>
        <div className="LeftInput">
          <span className="Value">{productSelected.shape.height1}</span>
          <span className="mm">mm</span>
        </div>

        <div className="RightInput">
          <span className="Value">
            {productSelected.shape.height2
              ? productSelected.shape.height2
              : productSelected.shape.height1}
          </span>
          <span className="mm">mm</span>
        </div>
        <div className="BottomInput">
          <span className="Value">{productSelected.shape.width}</span>
          <span className="mm">mm</span>
        </div>

        {trellisStyleIcon(
          productSelected.style,
          productSelected.shape.angle,
          false,
        )}
      </SummaryIconStyle>
    );
  }

  if (productSelected.name === 'panel') {
    return (
      <SummaryIconStyle productSelected={productSelected.name} >
        <div className="LeftInput">
          <span className="Value">{productSelected.shape.height}</span>
          <span className="mm">mm</span>
        </div>

        <div className="BottomInput">
          <span className="Value">{productSelected.shape.width}</span>
          <span className="mm">mm</span>
        </div>

        {panelIcon(
          productSelected.picketSize,
          productSelected.picketProfile,
          productSelected.style,
          false
        )
        }
      </SummaryIconStyle >
    )
  }

  return (
    <SummaryIconStyle productSelected={productSelected.name}>
      <div className="LeftInput">
        <span className="Value">
          {productSelected.shape.gateHeight}
        </span>
        <span className="mm">mm</span>
      </div>

      <div className="RightInput" style={{ visibility: 'hidden' }}>
        <span className="Value"></span>
        <span className="mm">mm</span>
      </div>
      <div className="BottomInput">
        <span className="Value">
          {productSelected.shape.gateWidth}
        </span>
        <span className="mm">mm</span>
      </div>

      {gateIcon(
        productSelected.shape.hingePlacement,
        productSelected.railSize,
        productSelected.picketProfile,
        productSelected.picketSize,
        productSelected.style,
        false
      )
      }
    </SummaryIconStyle>
  );
};

export const railThumbnails = (
  iconType: IconType,
) => {
  switch (iconType) {
    case 'largePicket':
      return (
        <img
          src={largePicketsIcon}
          alt="Large Picket"
          className="LargePicketIcon"
        />
      );
    case 'standardPicket':
      return (
        <img
          src={standardPicketsIcon}
          alt="Standard Picket"
          className="StandardPicketIcon"
        />
      );
    case 'largeRail':
      return (
        <img src={largeRailsIcon} alt="Large Rail" className="LargeRailIcon" />
      );
    case 'standardRail':
      return (
        <img
          src={standardRailsIcon}
          alt="Standard Rail"
          className="StandardRailIcon"
        />
      );
  }
};

export const GateIconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;



`;

// export const GateRailIconStyle = styled.div<{
//   hingePlacement?: 'left' | 'right';
//   thumbnail?: boolean;
// }>`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;

//   .RailsIcon {
//     position: absolute;
//     z-index: 1;
//   }

//   .RailsIcon,
//   .BaseIcon {
//     height: ${(props) => props.thumbnail && '70px !important'};
//     width: ${(props) => props.thumbnail && '70px !important'};
//     margin: ${(props) => props.thumbnail && '0px !important'};
//     transform: ${(props) => props.hingePlacement === 'right' && 'scaleX(-1)'};
//   }

//   .BaseIcon {
//     z-index: 0;
//   }

//   .RailsIcon,
//   .BaseIcon {
//     width: 130px;
//     margin: 0px 10px 10px 10px;
//     @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
//       width: 145px;
//       margin: 0px 10px 10px 10px;
//     }

//     @media screen and (min-width: 380px) {
//       width: 150px;
//       margin: 15px;
//     }

//     @media screen and (min-width: ${(props) => props.theme.bps}) {
//       width: 175px;

//       margin: 10px 15px;
//     }
//   }

//   .HingesIconRails {
//     position: absolute;
//     width: ${(props) => props.thumbnail && '5px !important'};
//     transform: ${(props) =>
//     props.hingePlacement === 'left'
//       ? props.thumbnail
//         ? 'translateX(-39px) scaleX(-1) !important;'
//         : 'translateX(-105px) scaleX(-1);'
//       : props.thumbnail
//         ? 'translateX(39px) !important;'
//         : 'translateX(105px) '};

//     @media screen and (min-width: 1px) {
//       transform: ${(props) =>
//     props.hingePlacement === 'left'
//       ? 'translateX(-74px) scaleX(-1);'
//       : 'translateX(74px) '};

//       width: 10px;
//     }

//     @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
//       width: 10px;
//       transform: ${(props) =>
//     props.hingePlacement === 'left'
//       ? 'translateX(-80px) scaleX(-1);'
//       : 'translateX(80px) '};
//     }

//     @media screen and (min-width: 380px) {
//       width: 11px;
//       transform: ${(props) =>
//     props.hingePlacement === 'left'
//       ? 'translateX(-83px) scaleX(-1);'
//       : 'translateX(83px) '};
//     }

//     @media screen and (min-width: ${(props) => props.theme.bps}) {
//       width: 15px;
//       transform: ${(props) =>
//     props.hingePlacement === 'left'
//       ? 'translateX(-98px) scaleX(-1);'
//       : 'translateX(98px) '};
//     }
//   }
// `;

export const SummaryIconStyle = styled.div<{
  productSelected: ProductTypeName;
}>`
  place-content: center;
  display: grid;
  grid-template-areas:
    'LeftInput Icon RightInput'
    '. BottomInput .';

  .Icon {
    width: 200px;
    grid-area: Icon;
    margin: 10px;
    color: '#375954'; 
  }
  .BaseIcon,
  .IconPreview {
    margin: 10px;
  }

  .BottomInput {
    grid-area: BottomInput;
    place-self: center;
  }

  .LeftInput {
    grid-area: LeftInput;
    transform: ${(props) =>
    props.productSelected === 'gate' && 'translateX(-15px)'};
  }
  .RightInput {
    grid-area: RightInput;
  }
  .mm {
    color: #699976;
    position: relative;
    top: -8px;
  }
  .Value {
    color: #353434;
    font-weight: 600;
  }

  .BottomInput,
  .RightInput,
  .LeftInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    max-width: 150px;
    font-size: 1.05rem;
  }
`;
