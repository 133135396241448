import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { gateIcon, panelIcon, railThumbnails } from '../Icons';
import { ProductTypeName } from '../models/types';
import { handleError, useCurrentGateData, useCurrentPanelData } from '../utils';
import { buttonSX } from '../styles';

export interface SelectRailSizeProps {
  productSelected: ProductTypeName;
}

export const SelectRailSize: React.FC<SelectRailSizeProps> = ({
  productSelected,
}) => {
  const panelData = useCurrentPanelData();
  const gateData = useCurrentGateData();
  const isConsumerPage = window.location.pathname.includes('consumer');

  const [picketSize, setPicketSize] = useState<'standard' | 'large'>('standard');

  const [railSize, setRailSize] = useState<'standard' | 'large'>(
    productSelected === 'panel' ||
      (productSelected === 'gate' && gateData.shape.gateWidth > 1200)
      ? 'large'
      : 'standard',
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const displayProductIcon = () => {
    return productSelected === 'gate'
      ? gateIcon(gateData.shape.hingePlacement, railSize, gateData.picketProfile, picketSize, gateData.style)
      : panelIcon(picketSize, panelData.picketProfile, panelData.style)
  };

  const handleSetGateRailSize = (size: 'large' | 'standard') => {
    if (
      productSelected === 'gate' &&
      gateData.shape.gateWidth > 1200 &&
      size === 'standard'
    ) {
      handleError(
        'When gate is wider than 1200 mm, you must select a large rail.  Please go back and adjust width.',
        'Invalid Dimensions',
      );
      return;
    }
    setRailSize(size);
    return;
  };

  const renderPicketRailSize = () => {
    return (
      <>
        <div className="Inputs">
          {panelData.picketProfile === 'blank' && (
            <div className="PicketsSizeInput">
              <Button
                onClick={() => setPicketSize('standard')}
                className="StandardButton"
              >
                <div className="ButtonTextWrapper">
                  <div className="Type">Standard:</div>
                  <div className="Dimensions">
                    68<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => setPicketSize('large')}
                className="LargeButton"
              >
                <div className="ButtonTextWrapper">
                  <div className="Type">Large:</div>
                  <div className="Dimensions">
                    90<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              </Button>
            </div>
          )}
        </div>
        {displayProductIcon()}

        <div className="InlineIconSelectors RailSizeInline">
          {panelData.picketProfile === 'blank' && (
            <div className="Row">
              <span className="RowLabel">Pickets </span>

              <div className="PicketIconSelect">
                <Button onClick={() => setPicketSize('standard')}>
                  {railThumbnails('standardPicket')}
                </Button>
                <Button onClick={() => setPicketSize('large')}>
                  {railThumbnails('largePicket')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderGateRailSize = () => {
    return (
      <>
        <div>
          {gateData.picketProfile === 'blank' && (
            <div className="PicketsSizeInput">
              <Button
                className="StandardButton"
                onClick={() => setPicketSize('standard')}
              >
                <div className="ButtonTextWrapper">
                  <div className="Type">Standard:</div>
                  <div className="Dimensions">
                    68<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              </Button>
              <Button
                className="LargeButton"
                onClick={() => setPicketSize('large')}
              >
                <div className="ButtonTextWrapper">
                  <div className="Type">Large:</div>
                  <div className="Dimensions">
                    90<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              </Button>
            </div>
          )}
          <div className="RailSizeInput">
            <Button
              className="StandardButton"
              onClick={() => handleSetGateRailSize('standard')}
            >
              {gateData.picketProfile === 'blank' ? (
                <div className="ButtonTextWrapper">
                  <div className="Type">Standard Rails:</div>
                  <div className="Dimensions">
                    90<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              ) : (
                <div className="ButtonTextWrapper">
                  <div className="Type">Standard:</div>
                  <div className="Dimensions">
                    90<span className="mm"> mm x </span> 19
                    <span className="mm"> mm</span>
                  </div>
                </div>
              )}
            </Button>
            <Button
              className="LargeButton"
              onClick={() => handleSetGateRailSize('large')}
            >
              {gateData.picketProfile === 'blank' ? (
                <div className="ButtonTextWrapper">
                  <div className="Type">Large Rails:</div>
                  <div className="Dimensions">
                    70<span className="mm"> mm x </span> 35
                    <span className="mm"> mm</span>
                  </div>
                </div>
              ) : (
                <div className="ButtonTextWrapper">
                  <div className="Type">Large:</div>
                  <div className="Dimensions">
                    70<span className="mm"> mm x </span> 35
                    <span className="mm"> mm</span>
                  </div>
                </div>
              )}
            </Button>
          </div>
        </div>

        {displayProductIcon()}

        <div className="IconSelection">
          {gateData.picketProfile === 'blank' && (
            <div className="Row">
              <span className="RowLabel">Pickets </span>
              <div className="PicketIconSelect">
                <Button onClick={() => setPicketSize('standard')}>
                  {railThumbnails('standardPicket')}
                </Button>
                <Button onClick={() => setPicketSize('large')}>
                  {railThumbnails('largePicket')}
                </Button>
              </div>
            </div>
          )}
          <div className="Row">
            <span className="RowLabel">Rails </span>
            <div className="RailIconSelect">
              <Button onClick={() => handleSetGateRailSize('standard')}>
                {railThumbnails('standardRail')}
              </Button>
              <Button onClick={() => handleSetGateRailSize('large')}>
                {railThumbnails('largeRail')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSelectRailSize = () => {
    switch (productSelected) {
      case 'panel':
        return renderPicketRailSize();
      case 'gate':
        return renderGateRailSize();
    }
  };

  const handleNext = () => {
    dispatch({
      type: 'SET_FIELDS',
      productType: productSelected,
      fieldValue: [{ fieldName: 'picketSize', value: picketSize }, { fieldName: 'railSize', value: railSize }],
    });

    if (productSelected === 'gate') {
      if (!isConsumerPage) {
        history.push('/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
      } else {
        history.push('/consumer/gate/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
      }
    }
    if (productSelected === 'panel') {
      if (!isConsumerPage) {
        history.push('/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
      } else {
        history.push('/consumer/panel/quantity');
        dispatch({ type: 'PROGRESS_STEP' });
      }
    }
  };

  return (
    <RailSizeStyle picketSize={picketSize} railSize={railSize}>
      <div className="MainContent">
        <Typography variant="h6" className="HeaderText">
          {productSelected === 'panel'
            ? 'Select the size of your panel pickets'
            : gateData.picketProfile === 'blank'
              ? 'Select the size of your gate pickets and rails'
              : 'Select the size of your gate rails'}
        </Typography>
        <div className="InputContainer">{renderSelectRailSize()}</div>
      </div>

      <Button
        onClick={() => handleNext()}
        variant="contained"
        sx={buttonSX}
      >
        NEXT
      </Button>
    </RailSizeStyle>
  );
};

const RailSizeStyle = styled.div<{
  picketSize?: 'large' | 'standard';
  railSize?: 'large' | 'standard';
}>`
  overflow-x: hidden;
  height: 98%;
  display: flex;
  flex-direction: column;

  .InputContainer {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .IconPreview {
    display: block;
    margin: 0 auto;
    width: 200px;
  }

  .GateSizeInput {
    display: inline-flex;
    flex-direction: row;
    font-size: 20px;

    .StandardButton {
      font-weight: ${(props) => props.railSize === 'standard' && 600};
      color: ${(props) =>
    props.railSize === 'standard' ? props.theme.green : props.theme.grey};
      text-align: left;
    }

    .LargeButton {
      font-weight: ${(props) => props.railSize === 'large' && 600};
      color: ${(props) =>
    props.railSize === 'large' ? props.theme.green : props.theme.grey};
      margin-left: 20px;
      text-align: left;
    }
  }

  .RailIconSelect {
    display: flex;
    justify-content: space-between;

    .StandardRailIcon,
    .LargeRailIcon {
      width: 50px;
    }
    .StandardRailIcon {
      filter: ${(props) => props.railSize !== 'standard' && 'opacity(0.10)'};
    }
    .LargeRailIcon {
      filter: ${(props) => props.railSize !== 'large' && 'opacity(0.10)'};
    }
  }

  .PicketsSizeInput {
    display: flex;
    .mm {
      font-size: x-small;
    }
    flex-direction: row;
    justify-content: space-around;
    font-size: 20px;

    .StandardButton {
      font-weight: ${(props) => props.picketSize === 'standard' && 600};
      color: ${(props) =>
    props.picketSize === 'standard' ? props.theme.green : props.theme.grey};
      text-align: center;
    }

    .LargeButton {
      font-weight: ${(props) => props.picketSize === 'large' && 600};
      color: ${(props) =>
    props.picketSize === 'large' ? props.theme.green : props.theme.grey};
      margin-left: 20px;
      text-align: center;
    }
  }

  .PicketIconSelect {
    .StandardPicketIcon,
    .LargePicketIcon {
      width: 50px;
    }
    .StandardPicketIcon {
      filter: ${(props) => props.picketSize !== 'standard' && 'opacity(0.1)'};
    }
    .LargePicketIcon {
      filter: ${(props) => props.picketSize !== 'large' && 'opacity(0.1)'};
    }
  }

  .RailSizeInput {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    font-size: 20px;

    .mm {
      font-size: x-small;
    }

    .StandardButton {
      text-align: center;
      font-weight: ${(props) => props.railSize === 'standard' && 600};
      color: ${(props) =>
    props.railSize === 'standard' ? props.theme.green : props.theme.grey};
    }

    .LargeButton {
      font-weight: ${(props) => props.railSize === 'large' && 600};
      color: ${(props) =>
    props.railSize === 'large' ? props.theme.green : props.theme.grey};
      margin-left: 20px;
      text-align: center;
    }
  }

  .IconSelection {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

`;
