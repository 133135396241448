import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { QuotePayload, PanelData, PanelItem } from '../models/interfaces';
import { MainComponentStyle, ConsumerSubtitle } from '../Routes';
import { env } from '../../profile';
import { createPanelVariants, dataMassager, handleError } from '../utils';
import { FinishQuote } from './FinishQuote';
import { Header } from './Header';
import { SelectDimensions } from './SelectDimensions';
import { SelectProfile } from './SelectProfile';
import { SelectQuantity } from './SelectQuantity';
import { SelectRailSize } from './SelectRailSize';
import { SelectStyle } from './SelectStyle';

export const CONSUMER_PANEL_ROUTE = '/consumer/panel';

export const ConsumerPanelWorkflow: React.FC = () => {
  const [openSummary, setOpenSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const consumerSteps = [
    'Dimensions',
    'Styles',
    'Profile',
    'Size',
    'Summary',
  ];

  const panelData = useSelector<QuotePayload, PanelData[]>((quotePayload) =>
    quotePayload.panelData.filter((panelData) => panelData.added),
  );

  const progressStep = useSelector<QuotePayload>(
    (quotePayload) => quotePayload.stepCounter,
  ) as number;

  const transitions = useTransition(progressStep, (p) => p, {
    from: { opacity: 0, position: 'absolute', height: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 350,
      easing: (t: number) => t * (2 - t),
    },
    reset: true,
  });

  const backToStart = () => {
    history.push('/consumer/panel');

    dispatch({
      type: 'PROGRESS_STEP',
      reset: true,
    });
  };

  const handleFinish = async () => {
    setLoading(true);
    await createPanelVariants({
      // Only panel data is going in, so only PanelItems will come out.
      panel: dataMassager([], [], panelData) as PanelItem[],
    })
      .then((res) => {
        const variantString = res.data
          .map((panel) => `updates[${panel.variantId}]=${panel.qty}`)
          .join('&');
        window.location.href = `${env?.SHOPIFY_STORE_URL}/cart/update?${variantString}`;
      })
      .catch(() => {
        setLoading(false);
        handleError('Error creating panel variants');
      });
  };

  const renderComponent = () => {
    const renderStep = (item: number) => {
      switch (item) {
        case 0:
          return (
            <SelectDimensions
              productSelected="panel"
              pushUrl={CONSUMER_PANEL_ROUTE}
            />
          );
        case 1:
          return (
            <SelectStyle productSelected="panel" pushUrl={CONSUMER_PANEL_ROUTE} />
          );
        case 2:
          return (
            <SelectProfile productSelected="panel" />
          );
        case 3:
          return (
            <SelectRailSize productSelected="panel" />
          );
        case 4:
          return (
            <SelectQuantity
              nextButtonText="Add"
              productSelected="panel"
              pushUrl={CONSUMER_PANEL_ROUTE}
            />
          );
        case 5:
          return (
            <FinishQuote
              productSelected="panel"
              backToStart={backToStart}
              setOpenSummary={setOpenSummary}
              completeButtonText="Review"
            />
          );
      }
    };

    return transitions.map(({ item, key, props }) => (
      <div className="Container" key={key}>
        <animated.div key={key} style={props} className="Animation">
          {renderStep(item)}
        </animated.div>
      </div>
    ));
  };

  return (
    <>
      <Header
        finishButtonText="Add to Cart"
        progressSteps={consumerSteps}
        productSelected="panel"
        handleFinish={handleFinish}
        progressStep={progressStep}
        setOpenSummary={setOpenSummary}
        openSummary={openSummary}
        _handleBack={() => {
          history.goBack();
          dispatch({
            type: 'PROGRESS_STEP',
            decrement: true,
          });
        }}
        backUrl={'/consumer/panel'}
      />
      <ConsumerSubtitle>Custom Picket Panels</ConsumerSubtitle>
      <MainComponentStyle>
        {loading ? (
          <CircularProgress
            style={{
              height: '100px',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            color="success"
          />
        ) : (
          renderComponent()
        )}
      </MainComponentStyle>
    </>
  );
};
