import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from './redux/redux';
import thunk from 'redux-thunk';

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
