import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  CONSUMER_TRELLIS_ROUTE,
  ConsumerTrellisWorkflow,
} from './components/ConsumerTrellisWorkflow';
import {
  CONSUMER_GATE_ROUTE,
  ConsumerGateWorkflow,
} from './components/ConsumerGateWorkflow';
import {
  CONSUMER_PANEL_ROUTE,
  ConsumerPanelWorkflow,
} from './components/ConsumerPanelWorkflow';
import { DEBTOR_ROUTE, DebtorWorkflow } from './components/DebtorWorkflow';

export const Routes: React.FC<RouteComponentProps<any, any, unknown>> = () => {
  return (
    <Router>
      <AppContainerStyle>
        <Switch>
          <Route path={CONSUMER_TRELLIS_ROUTE} component={ConsumerTrellisWorkflow} />
          <Route path={CONSUMER_GATE_ROUTE} component={ConsumerGateWorkflow} />
          <Route path={CONSUMER_PANEL_ROUTE} component={ConsumerPanelWorkflow} />
          {/* legacy route catch */}
          <Route path='/consumer' component={ConsumerTrellisWorkflow} />
          <Route path={DEBTOR_ROUTE} component={DebtorWorkflow} />
        </Switch>
      </AppContainerStyle>
    </Router>
  );
};

export default withRouter(Routes);

const AppContainerStyle = styled.div`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;

  .Container {
    display: flex;
    justify-content: center;
  }

  .AnimationContainer {
    position: relative;
    height: 100%;
    max-height: 225px;
    min-height: 150px;
    align-items: center;
    padding: 5px 0px;
    display: flex;

    place-content: space-around

  }

  .NewQuoteButton {
    margin: 40px auto;
  }
  .Buttons {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 150px;
  }

  .SoloBackButton {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 150px;
  }

  .HorizontalWhiteBar {
    background: #fffafa;
    width: 100%;
    top: 0;
    position: absolute;
    height: 50px;
    z-index: 1;
  }
`;

export const MainComponentStyle = styled.div`
  height: 100%;

  .IconPreview {
    display: block;
    margin: 0 auto;
    width: 130px;
    @media screen and (min-width: 480px) and (max-height: 690px) {
      width: 150px !important;
    }

    @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
      width: 145px;
    }

    @media screen and (min-width: 380px) {
      width: 150px;
    }

    @media screen and (min-width: ${(props) => props.theme.bps}) {
      width: 200px;
    }
  }

  .ProfileIcon {
    display: block;
    margin: 0 auto;
    width: 175px;
  }

  .InlineIconSelectors {
    display: flex;
    flex-direction: column;

    .Row {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      justify-content: space-between;
      
      .RowLabel {
        color: ${(props) => props.theme.darkGreen};
        font-weight: 600;
        font-size: small;
      }
    }
  }

  .RailsInline {
    display: flex;
    flex-direction: row;
  }

  .RailSizeInline {
    display: flex;

    flex-direction: row;
  }

  .HeaderText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Georgia", serif;
    font-size: large;
    font-weight: 600;
    letter-spacing: .5px;
    font-family: 'Neuton', serif;
    color: ${(props) => props.theme.darkGreen};
    padding: 0px 8px;
    text-align: center;
  }

  .SummaryText {
    max-width: 350px;
    text-align: center;
  }

  .MainContent {
    display: flex;
    flex-direction: column;
    height: calc(100% - 200px);
    min-height: 450px;
    max-width: 800px;
    margin: 0px 10px;
    align-self: center;

    @media screen and (max-height: 520px) {
      height: inherit;
    }

    @media screen and (min-width: 380px) {
      margin: 0px 30px;
    }
  }
`;

export const ConsumerSubtitle = styled.div`
  font-size: x-large;
  font-weight: 600;
  color: ${(props) => props.theme.darkGreen};
  text-align: center;
  font-family: 'Neuton', serif;
`;
