import { QuotePayload, TrellisData } from "../models/interfaces";
import { ProductTypeName } from "../models/types";
import { TrelliAction } from "./redux";

function handleTrellisActions(state: QuotePayload, action: TrelliAction) {
  switch (action.type) {
    case 'NEW_EMPTY_PRODUCT':
      return {
        ...state,
        product: 'trellis' as ProductTypeName,
        trellisData: state.trellisData.concat([
          {
            shape: {
              height1: 0,
              height2: 0,
              width: 0,
            },
            name: 'trellis',
            framed: false,
            quantity: 0,
            fixing: 'stainless',
            style: 'diagonal',
            added: false,
          } as TrellisData,
        ]),
      }
    case 'SET_FIELD': {
      const parts = action.fieldName.split('.');
      const current = state.trellisData[state.trellisData.length - 1];

      let newCurrent;
      if (parts[0] === 'shape') {
        newCurrent = {
          ...current,
          shape: {
            ...current.shape,
            [parts[1]]: action.value,
          },
        };
      } else {
        newCurrent = { ...current, [parts[0]]: action.value };
      }
      return {
        ...state,
        trellisData: state.trellisData
          .slice(0, state.trellisData.length - 1)
          //@ts-ignore
          .concat([newCurrent]),
      };
    }
    case 'SET_FIELDS': {
      let newState = state;

      action.fieldValue.map((fieldValue) => {
        const parts = fieldValue.fieldName.split('.');
        const current = newState.trellisData[newState.trellisData.length - 1];

        let newCurrent;

        if (parts[0] === 'shape') {
          newCurrent = {
            ...current,
            shape: {
              ...current.shape,
              [parts[1]]: fieldValue.value,
            },
          };
        } else {
          newCurrent = { ...current, [parts[0]]: fieldValue.value };
        }
        newState = {
          ...newState,
          trellisData: state.trellisData
            .slice(0, state.trellisData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
        return newState;
      });
      return newState;
    }
    case 'DELETE_ITEM':
      return {
        ...state,
        trellisData: state.trellisData.filter(
          (_: any, index: number) =>
            state.trellisData[index] !== state.trellisData[action.index],
        )
      }
    case "CHANGE_QUANTITY": {
      state.trellisData[action.index].quantity = action.quantity;
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default handleTrellisActions;