import { QuotePayload, GateData } from "../models/interfaces";
import { ProductTypeName } from "../models/types";
import { TrelliAction } from "./redux";

function handleGateActions(state: QuotePayload, action: TrelliAction) {
  switch (action.type) {
    case 'NEW_EMPTY_PRODUCT':
      return {
        ...state,
        product: 'gate' as ProductTypeName,
        gateData: state.gateData.concat([
          {
            shape: { gateHeight: 0, gateWidth: 0, hingePlacement: 'left' },
            quantity: 0,
            name: 'gate',
            picketProfile: 'blank',
            picketSize: 'standard',
            railSize: 'standard',
            style: 'square',
            added: false,
          } as GateData,
        ]),
      }
    case 'SET_FIELD': {
      const parts = action.fieldName.split('.');
      const current = state.gateData[state.gateData.length - 1];

      let newCurrent;
      if (parts[0] === 'shape') {
        newCurrent = {
          ...current,
          shape: {
            ...current.shape,
            [parts[1]]: action.value,
          },
        };
      } else {
        newCurrent = { ...current, [parts[0]]: action.value };
      }
      return {
        ...state,
        gateData: state.gateData
          .slice(0, state.gateData.length - 1)
          //@ts-ignore
          .concat([newCurrent]),
      };
    }
    case 'SET_FIELDS': {
      let newState = state;

      action.fieldValue.map((fieldValue) => {
        const parts = fieldValue.fieldName.split('.');
        const current = newState.gateData[newState.gateData.length - 1];

        let newCurrent;

        if (parts[0] === 'shape') {
          newCurrent = {
            ...current,
            shape: {
              ...current.shape,
              [parts[1]]: fieldValue.value,
            },
          };
        } else {
          newCurrent = { ...current, [parts[0]]: fieldValue.value };
        }
        newState = {
          ...newState,
          gateData: state.gateData
            .slice(0, state.gateData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
        return newState;
      });
      return newState;
    }
    case 'DELETE_ITEM':
      return {
        ...state,
        gateData: state.gateData.filter(
          (_: any, index: number) =>
            state.gateData[index] !== state.gateData[action.index],
        ),
      };
    case 'CHANGE_QUANTITY': {
      state.gateData[action.index].quantity = action.quantity;
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default handleGateActions;